import { createStore } from 'vuex'
import router from '../router/index'

export default createStore({
  state: {
  },
  mutations: {
    //页面跳转，通过params传递参数
    goToPage(state, payload) {
      router.push({
        name: payload.url,
        query: payload.param,
      })
    }
  },
  actions: {
  },
  modules: {
  }
})
