import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import axios from 'axios'
import VueAxios from "vue-axios";
import 'normalize.css'
import 'lib-flexible/flexible'
import 'element-plus/theme-chalk/index.css'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'

const app = createApp(App);

app.use(store).use(router).use(ElementPlus, { zhCn }).use(VueAxios, axios);
app.mount('#app');
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
